import { Link, useLocation } from 'react-router-dom';
import Logo from '/src/assets/logo.svg';
import { useState } from 'react';

export default function YourCart() {
	const isCartDomain = window.location.hostname.includes('cart.');
	const query = new URLSearchParams(useLocation().search);
	const planType = query.get('planType');
	const [plan, setPlan] = useState(planType);
	const planTypes = [
		{
			name: 'Monthly',
			price: 3.99
		},
		{
			name: 'Yearly',
			price: 32
		},
		{
			name: 'Lifetime',
			price: 96
		}
	];

	const changeUrlQuery = (key: string, value: string) => {
		query.set(key, value);
		window.history.pushState({}, '', `${window.location.pathname}?${query}`);
	};
	return (
		<div className="min-h-screen flex flex-col">
			<header className="flex justify-between gap-2 items-center p-4">
				<div className="flex items-center">
					<img className="w-10 h-10" src={Logo} alt="logo" />
					{isCartDomain ? (
						<a href="https://lifetracker.live" className="text-[#8E4600] font-extrabold text-2xl">
							Life Tracker
						</a>
					) : (
						<Link to="/" className="text-[#8E4600] font-extrabold text-2xl">
							Life Tracker
						</Link>
					)}
				</div>
			</header>

			<main className="flex flex-col justify-center items-center flex-grow dark:bg-lt-dark-primary-bg">
				<h1 className="w-10/12 md:w-6/12 font-bold mb-5 text-3xl text-lt-beige">Your Cart</h1>
				<div className="flex gap-10 w-10/12 md:w-6/12 justify-center">
					<div className="px-5 pb-5 border border-lt-beige rounded-lg w-full">
						<h2 className="font-bold text-2xl text-lt-beige border-b border-b-lt-beige py-4">Premium Plan</h2>
						<label className="form-control w-full mt-5">
							<div className="flex justify-between items-center">
								<div className="flex flex-col w-1/2">
									<span className="label-text font-bold">Period</span>
									<select
										onChange={(e) => {
											changeUrlQuery('planType', e.target.value);
											setPlan(e.target.value);
										}}
										className="select select-bordered w-full"
									>
										{planTypes.map((plan) => (
											<option key={plan.name} value={plan.name} selected={planType === plan.name}>
												{plan.name}
											</option>
										))}
									</select>
								</div>

								<div className="flex flex-col justify-center">
									{/* <span className="font-bold text-2xl">Subtotal</span> */}
									<div>
										<div className="font-bold text-xl">
											${planTypes.find((planType) => planType.name === plan)?.price} / month
										</div>
										{plan === 'Yearly' && (
											<div className="text-sm text-lt-beige w-full flex justify-center">
												<span className="line-through decoration-1 text-lg">$48 / month</span>
											</div>
										)}
									</div>
								</div>
							</div>
						</label>
						{/* <div className="mt-8">
							<span className="label-text font-bold">Have a coupon code?</span>
							<div className="flex gap-2 mt-2">
								<Input
									onChange={(e) => {
										console.log(e.target.value);
									}}
									type="text"
									placeholder="Enter your coupon code"
									className="w-full"
								/>
								<Button variant="btn-primary-light" type="button">
									Apply
								</Button>
							</div>
						</div> */}
						<div className="mt-8">
							<Link
								className="bg-lt-beige text-white px-2 py-3 font-semibold text-lg rounded-lg"
								to={`/auth/register?planType=${plan}`}
							>
								Continue
							</Link>
						</div>
					</div>
				</div>
			</main>

			<footer className="w-full flex justify-center p-2 mt-4">
				<div className="w-1/2 flex justify-between">
					<h6 className="text-[#333333] dark:text-white text-2xs sm:text-xs md:text-base">
						© 2024 Life Tracker. All rights reserved.
					</h6>
					<ul className="flex gap-10 text-xs sm:text-xl text-[#333333] dark:text-white">
						<li>
							<Link to="/en/privacy-policy" className="hover:underline">
								Privacy Policy
							</Link>
						</li>
						<li>
							<Link to="/en/terms-of-service" className="hover:underline">
								Terms of Service
							</Link>
						</li>
					</ul>
				</div>
			</footer>
		</div>
	);
}
